<style lang="scss">
.newsletter {
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      img {
        display: block;
        margin: 0 auto;
      }
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  h2 {
    margin-top: 0;
  }
  h4 {
    font-size: 130%;
    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 5px;
      margin-top: 15px;
      background: #eee;
    }
    @media (max-width: 768px) {
      font-size: 130%;
    }
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .custom-link-newsletter {
    margin-top: 80px;
    text-align: center;
    a {
      display: inline-block;
      padding: 15px 20px;
      background: $blue;
      color: #fff !important;
      &:hover {
        background: $blue-dark;
      }
    }
  }
}
</style>

<template>
  <div class="content newsletter container">
    <h1>Newsletter</h1>
    <p class="intro-txt">Newsletter sind ideal, um Ihre Zielgruppen punktgenau anzusprechen, denn Information und Werbung ergänzen sich bei diesen Formaten optimal. Nutzen Sie die Interessen der User als inhaltliche Anknüpfungspunkte für die Platzierung Ihrer Angebote.</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-mittagsnewsletter.jpg" alt="Mittagsnewsletter" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Mittags-Newsletter</h2>
          <p>Der Mittags-Newsletter versorgt unsere Leser täglich mit den wichtigsten Nachrichten. Erreichen Sie mit Ihrer Werbebotschaft über 18.000 Newsletter-Abonnenten in einem tagesaktuellen Umfeld.Das Werbemittel wird direkt zwischen den Artikeln platziert und erreicht dadurch höchste Aufmerksamkeit</p>
          <div class="preis-box">
            <span>Tarif ab: <strong>100,00 </strong></span>
          </div>
          <CollapsibleButton link="mittags-newsletter-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Mittags-Newsletter</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="mittags-newsletter-collapse">
      <div class="tabelle-box">
        <div class="headline">
          <strong>NEWSLETTER AD</strong>
          <div class="info-txt">300 x 300 PX *</div>
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-3 text-align-left">Pro Aussendung</div>
          <div class="col-lg-3 text-align-right">Kombination</div>
          <div class="col-lg-3 text-align-right">Steiermark</div>
          <div class="col-lg-3 text-align-right">Kärnten</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Pro Aussendung</div>
          <div class="col-lg-3 col-6 text-align-left text-right-small">Mittags-Newsletter</div>
          <div class="col-6 text-align-left visible-small">Kombination</div>
          <div class="col-lg-3 col-6 text-align-right">180,00</div>
          <div class="col-6 text-align-left visible-small">Steiermark</div>
          <div class="col-lg-3 col-6 text-align-right">110,00</div>
          <div class="col-6 text-align-left visible-small">Kärnten</div>
          <div class="col-lg-3 col-6 text-align-right">70,00</div>
        </div>

        <div class="info-txt-tabelle">Datenanlieferung als jpg-Datei, max. 80 kB. Bitte beachten Sie die Vorlaufzeit von 5 Werktagen. Für Newsletter Ad gilt ein Mindestbuchungsvolumen von 100,00.</div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
      <br /><br />
      <div class="hinweis-box text-center">Bei Buchung von drei aufeinanderfolgenden Tagen erhalten Sie einen <strong>VIERTEN TAG KOSTENLOS</strong> dazu!</div>
    </Collapsible>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-reise-newsletter.jpg" alt="Reise-Newsletter" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Reise-Newsletter</h2>
          <p>Der Reise-Newsletter inspiriert unsere Leser einmal in der Woche (immer sonntags) mit den schönsten Urlaubsdestinationen. Erreichen Sie mit Ihrer Werbebotschaft über 10.000 reiseinteressierte Abonnenten. In jedem Reise-Newsletter wird nur eine exklusive Werbebotschaft platziert.</p>
          <div class="preis-box">
            <span>Tarif ab:<strong>300,00 </strong></span>
          </div>
          <CollapsibleButton link="reise-newsletter-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Reise-Newsletter</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="reise-newsletter-collapse">
      <div class="tabelle-box">
        <div class="headline">
          <strong>NEWSLETTER AD</strong>
          <div class="info-txt">300 x 300 PX *</div>
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-3 text-align-left">Pro Aussendung</div>
          <div class="col-lg-3 text-align-right">Kombination</div>
          <div class="col-lg-3 text-align-right">Steiermark</div>
          <div class="col-lg-3 text-align-right">Kärnten</div>
        </div>

        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Pro Aussendung</div>
          <div class="col-lg-3 col-6 text-align-left text-right-small">Reise-Newsletter</div>
          <div class="col-6 text-align-left visible-small">Kombination</div>
          <div class="col-lg-3 col-6 text-align-right">300,00</div>
          <div class="col-6 text-align-left visible-small">Steiermark</div>
          <div class="col-lg-3 col-6 text-align-right">-</div>
          <div class="col-6 text-align-left visible-small">Kärnten</div>
          <div class="col-lg-3 col-6 text-align-right">-</div>
        </div>
        <div class="info-txt-tabelle">Datenanlieferung als jpg-Datei, max. 80 kB. Bitte beachten Sie die Vorlaufzeit von 5 Werktagen. Für Newsletter Ad gilt ein Mindestbuchungsvolumen von 100,00.</div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
      <br /><br />
      <div class="hinweis-box text-center">Bei Buchung von drei aufeinanderfolgenden Tagen erhalten Sie einen VIERTEN TAG KOSTENLOS dazu!</div>
    </Collapsible>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/marktplaetze/d-karriere-newsletter.jpg" alt="Karriereleiter-Newsletter" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Karriereleiter-Newsletter</h2>
          <p><strong>Wir bringen weiter, was dich weiterbringt.</strong><br />Der Karriereleiter-Newsletter versorgt unsere Leser jeden Dienstag mit den wichtigsten Karriere-Themen, stellt Top-Arbeitgeber in Steiermark und Kärnten vor und liefert die Top Jobs aus der Region.</p>
          <p>Präsentieren Sie sich, Ihre Job-Angebote und Ihre Arbeit gegenüber der richtigen Zielgruppe und erreichen Sie somit Ihre zukünftigen BewerberInnen.</p>
          <div class="preis-box">
            <span>Tarif ab: <strong> 150,00 </strong></span>
          </div>
          <CollapsibleButton link="karrienewsletter-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Karriere-Newsletter</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="karrienewsletter-collapse">
      <div class="row">
        <div class="col-lg-12">
          <h3>Tob Jobs der Woche</h3>
          <p>Ihre digitale Stellenanzeige im Karriereleiter Newsletter.</p>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/marktplaetze/bsp-top-jobs.png" alt="Bsp. Tob Jobs" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <strong>LEISTUNG</strong>
          <ul class="content-ul">
            <li>Platzierung Ihres Stellenangebotes im Karriereleiter-Newsletter</li>
            <li>Abbildung möglich in der Kategorie Steiermark oder Kärnten</li>
            <li>Abbildung inkl. Logo, Job-Titel, Firmenname, Standort, „Mehr erfahren“-Link zur Abbildung des Stellenangebotes auf der Unternehmensseite</li>
          </ul>
          <div class="preis-box">
            <span><strong>Tarif:</strong> 150,00</span>
          </div>
        </div>
      </div>
    </Collapsible>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/newsletter-wien-memo.jpg" alt="Wien.Memo Newsletter" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>wien.memo</h2>
          <p><strong>Für Wiener*innen und solche, die es nie werden wollten.</strong> wien.memo ist der erste Stadt-Newsletter seiner Art. Kurz, prägnant und auf den Punkt gebracht. 5 x in der Woche kostenlos für unsere jungen, urbanen Leser*innen, die sich in aktuelle Stadtgespräche einbringen möchten. Bereits mehr als <strong>7.000 Abonnenten</strong>.</p>
          <div class="preis-box">
            <span>Tarif ab: <strong>150,00 </strong></span>
          </div>
          <CollapsibleButton link="wienmemo-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum wien.memo</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="wienmemo-collapse">
      <h2>Werbelösung</h2>
      <h3>Newsletter-Werbemöglichkeiten</h3>
      <p>Präsentieren Sie Ihre Produkte und Dienstleistungen einer Zielgruppe, die News vorwiegend auf Smartphones und Tablets lesen. Nutzen Sie unser redaktionelles Umfeld, um Ihre Botschaften zu kommunizieren und profitieren Sie von der Glaubwürdigkeit unserer Marke.</p>
      <br />
      <div class="row justify-content-center aling-self-center">
        <div class="col-lg-4 ">
          <h4>Display Ads</h4>
          <p>Anzeigen im Form von Bildern.<br /><small>(300 x 300 Px)</small></p>
        </div>
        <div class="col-lg-4 ">
          <h4>Advertorials</h4>
          <p>Von unserer PR-Redaktion verfasste Texte inklusive Verlinkung zur Ihrer Homepage.<br /><small>(ca. 500 Wörter, Bildmaterial: 300 x 250 Px)</small></p>
        </div>
        <div class="col-lg-4">
          <h4>Newsletter Sponsoring</h4>
          <p>Sponsoring einer gesamten wien.memo-Ausgabe mit 3 - 5 Geschichten aus Ihrem Unternehmen. Die Beiträge werden von unserer PR-Redaktion verfasst. Optional sind die Geschichten aufteilbar auf mehrere Erscheinungen.</p>
        </div>
      </div>

      <div class="tabelle-box tabelle-width-50">
        <div class="headline">
          <strong>Preise</strong>
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-6 text-align-left">Tarif</div>
          <div class="col-lg-6 text-align-right">Preis</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Tarif</div>
          <div class="col-lg-6 col-6 text-align-left text-right-small">Display-Ad-Tarif **</div>
          <div class="col-6 text-align-left visible-small">Preis</div>
          <div class="col-lg-6 col-6 text-align-right">150,00</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Tarif</div>
          <div class="col-lg-6 col-6 text-align-left text-right-small">Advertorial-Tarif</div>
          <div class="col-6 text-align-left visible-small">Preis</div>
          <div class="col-lg-6 col-6 text-align-right">850,00</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left visible-small">Tarif</div>
          <div class="col-lg-6 col-6 text-align-left text-right-small">Newsletter-Sponsoring</div>
          <div class="col-6 text-align-left visible-small">Preis</div>
          <div class="col-lg-6 col-6 text-align-right">1.600,00</div>
        </div>
        <br />
        <div class="hinweis-box text-center">** Bei Buchung der gesamten Woche,<br />sind die letzten zwei Display-Ads gratis!</div>
      </div>

      <br />
      <div class="download-pdf">
        <div class="line"><span>DOWNLOAD</span></div>
        <a href="../../pdf/digital/Wien-Memo-07-2021.pdf" target="_blank">
          <div class="row">
            <div class="col-8 justify-content-center align-self-center">
              Tarif wien.memo
            </div>
            <div class="col-4 justify-content-end align-self-center text-right">
              <span>1.499 kb // PDF</span>
            </div>
          </div>
        </a>
      </div>
      <!--<h3>wien.memo Podcast</h3>
      <p>Hört, hört! Nutzen Sie die Podcasts der Kleinen Zeitung. In unterschiedlichsten Formaten werden Serien aus den Bereichen Lifestyle, Politik, Wirtschaft und Stadtleben produziert. Mit einer eigenen Redaktion in Wien, die für den Content zuständig ist und ständig neue Ideen entwickelt.</p>
      <br />
      <div class="row justify-content-center aling-self-center">
        <div class="col-lg-4 ">
          <h4>Einzigartiges Zielgruppensegment</h4>
          <p>Erreichen Sie mit Podcasts Zielgruppen, die analog nur noch schwer erreichbar sind: gut gebildet, jung und urban<sup>1</sup>.</p>
        </div>
        <div class="col-lg-4 ">
          <h4>Crossmedialer Werbedruck</h4>
          <p>Profitieren Sie von der zweitgrößten Kauftageszeitung österreichweit<sup>2</sup>. Wir veröffentlichen viele der Gespräche in Print und Online. Stets mit dem Hiwneis auf den Podcast.</p>
        </div>
        <div class="col-lg-4">
          <h4>Podcast-Werbung wirkt</h4>
          <p>Nutzen Sie das neue Werbeformat und profitieren Sie von der Glaubwürdigkeit unserer Marke und der von Podcasts.</p>
        </div>
        <div class="quelle-txt"><sup>1</sup> OMR-Studie, August 2018; <sup>2</sup> täglich 773.000 Leser (MA 2018/2019), 1.919.000 Unique Usern (ÖWA Plust 2019-III)</div>
      </div>

      <div class="tabelle-box tabelle-width-50">
        <div class="headline">
          <strong>Preise</strong>
        </div>
        <div class="row head-row hide-small">
          <div class="col-lg-6 text-align-left">Tarif</div>
          <div class="col-lg-6 text-align-right">Preis</div>
        </div>
        <div class="row content-row ">
          <div class="col-4 text-align-left visible-small">Tarif</div>
          <div class="col-lg-6 col-8 text-align-left text-right-small">Host-Read-Ad (gesprochen vom Moderator) / (30 Sek.)</div>
          <div class="col-4 text-align-left visible-small">Preis</div>
          <div class="col-lg-6 col-8 text-align-right justify-content-center align-self-center">2.000</div>
        </div>
        <div class="row content-row">
          <div class="col-4 text-align-left visible-small">Tarif</div>
          <div class="col-lg-6 col-8 text-align-left text-right-small">Jingle- oder Sponsorship-Tarif (5-10 Sek.)</div>
          <div class="col-4 text-align-left visible-small">Preis</div>
          <div class="col-lg-6 col-8 text-align-right justify-content-center align-self-center">1.000</div>
        </div>
        <div class="row content-row">
          <div class="col-4 text-align-left visible-small">Tarif</div>
          <div class="col-lg-6 col-8 text-align-left text-right-small">Jingle- oder Sponsorship-Tarif (ab 10 Sek.)</div>
          <div class="col-4 text-align-left visible-small">Preis</div>
          <div class="col-lg-6 col-8 text-align-right justify-content-center align-self-center">1.500</div>
        </div>
        <br />
        <div class="hinweis-box text-center">Bei Buchung von 4 Folgen ist die 5. Folge gratis!</div>
      </div>
      <p class="custom-link-newsletter"><a href="https://www.kleinezeitung.at/service/podcast/index.do" target="_blank">Hier geht es zu unseren Podcasts</a></p>
      -->
    </Collapsible>
    <!--
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-wienmemo.jpg" alt="Wien.Memo Podcast" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Wien.Memo Podcast</h2>
          <p>Wussten Sie, dass 93 % der Hörer Werbung in Podcasts nicht nur akzeptieren, sondern sich drei Viertel die Werbung ganz bewusst bis zum Ende anhören? Nutzen Sie unsere Wien.Memo-Podcasts und erreichen Sie eine gut gebildete, junge und urbane Hörerschaft, die analog nur mehr schwer zu erreichen ist.</p>
          <div class="preis-box">
            <span>ab <strong>€ 100,00 </strong></span>
          </div>
          <CollapsibleButton link="wienmemo-podcast-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Wien.Memo Podcast</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="wienmemo-podcast-collapse">
      wien.memo Podcast
    </Collapsible>-->
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/digital/Newsletter-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Newsletter
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>887 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'Newsletter',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
</script>
